import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>Advanced Architecting on AWS へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/advanced-architecting-aws/" target="blank">Advanced Architecting on AWS について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>受講準備</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・ブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・本コースではマイクの使用は必須ではありません。スピーカー（やヘッドホンなど）を準備いただければ OK です。<br/>
        ・可能であればモニターは複数用意していただくと演習（ラボ）を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・音声が聞こえない方は P. 28 をご確認ください<br/>
        ===== <a href="https://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">【2】受講者ガイド利用手順</a> =====<br/>
        ・コース開始前に <a href="https://evantage.gilmoreglobal.com/" target="blank">こちら</a> からアカウントの作成をお願いします。<br/>
        ・講義で用いる教材は「Student Guide」、演習の手順書は「Lab Guide」となります。<br/>
        ・ただし、スライドの並び替えや取捨選択、補足資料の追加などをしておりますので、教材ではなく Webex の画面を見ながらの受講をお願いします。<br/>
        ・トレーニングの期間中に必ず 1 度は教材への閲覧をお願いします。<br/>
        ・教材は、引き換え後、720 日間閲覧可能です。<br/>
        ※ 稀に、引き換え後にガイドが表示されないことがあります。同期の問題であることが多いので、午後に [更新] ボタンを押してみてください。<br/>
        ====== 【3】受講者ポータルへのアクセス方法 =====<br/>
        ・ログインページは <a href="" target="blank">こちら(XXXXXXXX - XXXXXXXX)</a> です。<br/>
        ・受講者ポータルから、ラボ（ハンズオン）の実施や、教科書の閲覧ができます。<br/>
        ・研修を申し込む際に使用したアカウントでログインをお願いします。<br/>
        ・受講者ポータルでのラボ実施は、トレーニング中の3日間のみアクセス可能です。ご留意下さいませ。<br/>
        </p>
        <hr/>
        <h5>受講準備（Jam）</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・同じくブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・Jam はマイクの使用を推奨しております。コミュニケーションを円滑にするために使用下さい。<br/>
        ・可能であればモニターは複数用意していただくと「チャレンジ」を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・同じく音声が聞こえない方は P. 28 をご確認ください<br/>
        ====== 【2】登録に関する内容および Jam 用の補足資料に関して =====<br/>
        ・Jam への登録に関しては<a href="https://advarch-appendix.s3.ap-northeast-1.amazonaws.com/Jam_pre_register.pdf" target="blank">こちら（AWS Jam アカウント登録案内）</a>の資料をご参照下さい。<br/>
        ・上記につきましてまずはログインが必要になりますので講義内「受講者ポータル」にアクセス頂いた方法と同じ方法でログインの上、登録を進行して下さい。<br/>
        ・Jam のオリエンテーション時にご案内している資料の抜粋版は<a href="https://advarch-appendix.s3.ap-northeast-1.amazonaws.com/Jam_Appendix.pdf" target="blank">こちら</a>になります。ダウンロードなど頂き、適宜ご確認下さい。<br/>
        ====== 【3】Jam 参加に関する URL のご紹介 =====<br/>
        ・Jam イベント（今回の限定）の URL は<a href="" target="blank">こちら</a>になります。抜けてしまった際のために URL はお控え下さい！（未使用）<br/>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          本トレーニング内で適宜まとめております資料につきましては別途 PDF にて切り出しております。 (転送等はお控え下さいますよう、お願い致します。)<br/>
          <a href="https://advarch-appendix.s3.ap-northeast-1.amazonaws.com/AdvancedArchitectingOnAWS_Module_Appendix.pdf" target="blank">トレーニング付加資料 (PDF)</a><br />
          <a href="https://advarch-appendix.s3.ap-northeast-1.amazonaws.com/AdvancedArchitectingOnAWS_Module_Appendix_IndexOnly.pdf" target="blank">トレーニング付加資料 (PDF)　＊まとめのみ</a><br />
          <a href="https://advarch-appendix.s3.ap-northeast-1.amazonaws.com/AdvancedArchitectingOnAWS_Lab_Appendix.pdf" target="blank">ラボのポイント (PDF)</a>
        </p>
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>アーキテクチャ設計の基礎</td>
            <td><span class="point_box"><b>ラボ1</b></span>Amazon S3 VPC エンドポイントの通信を保護する</td>
          </tr>
          <tr>
            <td>2</td>
            <td>単一アカウントから複数アカウントへ</td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>ハイブリッド接続</td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>ネットワークを接続する</td>
            <td><span class="point_box"><b>ラボ2</b></span>Transit Gateway を設定する</td>
          </tr>
        </table>
        <hr/>
        <h5>Day 2</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>6</td>
            <td>コンテナ</td>
            <td><span class="point_box"><b>ラボ3</b></span>AWS Fargate と Amazon ECS を使ってアプリケーションをデプロイする</td>
          </tr>
          <tr>
            <td>7</td>
            <td>継続的インテグレーション/継続的デリバリー (CI/CD)</td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>高可用性と DDoS</td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>データの保護</td>
            <td></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 3</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>10</td>
            <td>大規模データストア</td>
            <td><span class="point_box"><b>ラボ4</b></span>Lake Formation を使ってデータレイクを設定する</td>
          </tr>
          <tr>
            <td>13</td>
            <td>エッジのためのアーキテクチャ設計</td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>ワークロードの移行</td>
            <td><span class="point_box"><b>ラボ5</b></span>AWS DataSync と Storage Gateway を使用してオンプレミスの NFS 共有を移行する</td>
          </tr>
          <tr>
            <td>12</td>
            <td>コストの最適化</td>
            <td></td>
          </tr>
          <tr>
            <td>エンディング</td>
            <td>コースのまとめ＆アンケート入力</td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール1（アーキテクチャ設計の基礎）</h5>
        ・<a href="https://aws.amazon.com/jp/architecture/well-architected" target="blank">AWS Well-Architected</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/aws-well-architected-guide2022/" target="blank">【外部】AWS Well-Architected ドキュメントが読みやすくなりました！！（AWS Well-Architected ドキュメントの歩き方2022）</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/updates-aws-well-architected-framework-20240627/" target="blank">【外部】AWS Well-Architected Framework がアップデート(2024年6月27日)されたので変更点を Well-Architected Tool で比較してみた</a><br/>
        ・<a href="https://wa.aws.amazon.com/index.ja.html" target="blank">AWS Well-Architected フレームワーク（ホワイトペーパー）</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/aws-well-architected-whitepaper/" target="blank">AWS Well-Architected フレームワークホワイトペーパー 日本語版の更新</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/" target="blank">AWS グローバルインフラストラクチャ</a><br/>
        ・<a href="https://aws.amazon.com/jp/vpc/faqs/" target="blank">「Amazon VPC のよくある質問」</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/improve-your-website-availability-with-amazon-cloudfront/" target="blank">Amazon CloudFront を活用したウェブサイトの可用性向上</a><br/>
        <hr/>
        <h5>モジュール2（単一アカウントから複数アカウントへ）</h5>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202007/multi-accounts-best-practice/" target="blank">AWS マルチアカウント管理を実現するベストプラクティスとは ?</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-19_Governance_at_Scale.pdf" target="blank">セキュアでスケーラブルな AWS アカウント統制プラクティス最新動向</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/security-pillar/sec_securely_operate_multi_accounts.html" target="blank">SEC01-BP01 アカウントを使用してワークロードを分ける</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_evaluation-logic.html" target="blank">ポリシーの評価論理</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/security-pillar/security.html#design-principles" target="blank">設計原則 (Well Architected 「セキュリティの柱」)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/framework/sec_identities_enforce_mechanisms.html" target="blank">SEC02-BP01 強力なサインインメカニズムを使用する</a><br/>
        ・<a href="https://aws.amazon.com/jp/iam/identity-center/faqs/" target="blank">AWS IAM アイデンティティセンター (AWS Single Sign-On の後継) よくある質問</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/singlesignon/latest/userguide/tutorials.html" target="blank">入門チュートリアル（サポートされている ID プロバイダー）</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/prescriptive-guidance/latest/migration-aws-environment/understanding-landing-zones.html" target="blank">ランディングゾーンとは</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/multi-accounts-and-control-tower/" target="blank">スタートアップにおけるマルチアカウントの考え方と AWS Control Tower のすゝめ</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20220428_17th_ISV_DiveDeepSeminar_Control_Tower.pdf" target="blank">AWS Control Towerでマルチアカウント管理しませんか</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/controltower/latest/userguide/controls.html#control-behavior" target="blank">コントロールの動作とガイダンス</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/controltower/latest/userguide/how-control-tower-works.html" target="blank">AWS Control Tower の仕組み</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/prescriptive-guidance/latest/patterns/migrate-an-aws-member-account-from-aws-organizations-to-aws-control-tower.html" target="blank">AWS メンバーアカウントを AWS Organizations から AWS Control Tower に移行</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20220428_17th_ISV_DiveDeepSeminar_hatena.pdf" target="blank">​マルチアカウント環境に現代のベストプラクティスを取り入れていく話</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-02_AWS-Summit-2023_CHUGAI-PHARMACEUTICAL_FINAL.pdf" target="blank">中外製薬が実現したスピードとガバナンスが両立された「真の全社共通クラウド基盤」(CCI) とは</a><br/>
        ・<a href="https://fdsa-life.jp/blog/?p=1629" target="blank">【外部】リスクの統制「予防と発見」を活用する。【監査技術の応用】</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/controltower/latest/userguide/cfct-overview.html" target="blank">AWS Control Tower のカスタマイズ (CfCT) の概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/announcing-baseline-environment-on-aws/" target="blank">AWS環境にセキュアなベースラインを提供するテンプレート「Baseline Environment on AWS」のご紹介</a><br/>
        <hr/>
        <h5>モジュール3（ハイブリッド接続）</h5>
        ・<a href="https://www.ccsnews.jp/ccs2/2020/2q/2020_2Qamazonvpnshionogi.htm" target="blank">【外部】AWSがクラウドVPNでシオノギグループのテレワーク実現</a><br/>
        ・<a href="https://www.itmedia.co.jp/news/articles/2009/23/news043.html" target="blank">【外部】社内システム使えず「テレワークできない」→4000人がVPN同時接続　シオノギ製薬グループの“激動の5日間”</a><br/>
        ・<a href="https://catalog.us-east-1.prod.workshops.aws/workshops/be2b90c2-06a1-4ae6-84b3-c705049d2b6f/ja-JP" target="blank">AWS Client VPN Basic ハンズオン</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/casestudy-capcom-academia-collaboration-by-game-development/" target="blank">AWS で実現するゲーム開発体験授業 – カプコンと近畿大学の産学連携</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/whitepapers/latest/aws-vpc-connectivity-options/software-site-to-site-vpn.html" target="blank">ソフトウェア Site-to-Site VPN</a><br/>
        ・<a href="https://aws.amazon.com/jp/directconnect/partners/" target="blank">AWS Direct Connect デリバリーパートナー</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20210209-AWS-Blackbelt-DirectConnect.pdf#page=16" target="blank">[AWS Black Belt Online Seminar] AWS Direct Connect</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-79_AWS_Summit_Online_2020_QUICK-Corp.pdf#page=10" target="blank">金融エンタープライズのミッション　クリティカルシステム移行時の勘所</a><br/>
        <hr/>
        <h5>モジュール4（専用インフラストラクチャ　＊Skip　＊後学の為の参考リンクのみ）</h5>
        ・<a href="https://aws.amazon.com/jp/storagegateway/" target="blank">AWS Storage Gateway</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_AWS-Storage-Gateway_0131_v1.pdf" target="blank">AWS Storage Gateway AWS Black Belt Online Seminar</a><br/>
        ・<a href="https://aws.amazon.com/jp/outposts/" target="blank">AWS Outposts ファミリー</a><br/>
        ・<a href="https://engineers.ntt.com/entry/2022/03/15/102459" target="blank">【外部】【日本初導入】 AWS Outposts ラックを徹底解説 第1回 〜導入・利用方法の概要〜</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/localzones/" target="blank">AWS Local Zones</a><br/>
        ・<a href="https://aws.amazon.com/jp/wavelength/" target="blank">AWS Wavelength</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20220407-AWS_SmartFactory_3_MAKINO-Milling.pdf" target="blank">キャリア5G × AWS Wavelength × iAssist で工場にサーバレスで製造の自動化を実現</a><br/>
        <hr/>
        <h5>モジュール5（ネットワークを接続する）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/framework/rel_planning_network_topology_prefer_hub_and_spoke.html" target="blank">REL02-BP04 多対多メッシュよりもハブアンドスポークトポロジを優先する</a><br/>
        ・<a href="https://blog.serverworks.co.jp/tech/2020/06/30/transit-gateway-routing/" target="blank">【外部】Transit Gatewayのルーティング仕様を分かりやすく解説してみる </a><br/>
        ・<a href="https://catalog.us-east-1.prod.workshops.aws/workshops/e0d1c19d-c80b-4695-a3fc-5c4a25132f47/ja-JP/" target="blank">AWS Transit Gateway ハンズオン</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/vpc/latest/tgw/tgw-best-design-practices.html" target="blank">Transit Gateway 設計のベストプラクティス</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/smk-corporation-aws-transit-gateway-inter-region-peering/" target="blank">AWS導入事例: SMK株式会社 AWS Transit Gateway Inter-Region Peeringを活用したグローバルネットワークHub</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/hybrid-networking-lens/security-pillar.html" target="blank">Security pillar (Hybrid Networking Lens)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/ram/latest/userguide/shareable.html" target="blank">共有可能な AWS リソース</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/10-things-network.pdf" target="blank">AWS のネットワークで知っておくべき10のこと</a><br/>
        <hr/>
        <h5>モジュール6（コンテナ）</h5>
        ・<a href="https://aws.amazon.com/jp/compare/the-difference-between-containers-and-virtual-machines/" target="blank">コンテナと仮想マシンはどのように異なりますか?</a><br/>
        ・<a href="https://aws.amazon.com/jp/getting-started/decision-guides/containers-on-aws-how-to-choose/" target="blank">AWS コンテナサービスの選択</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/framework/perf-compute.html" target="blank">コンピューティング</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/202112_AWS_BlackBelt_CON241_ECR.pdf" target="blank">Amazon Elastic Container Registry Amazon Elastic Container Registry Public AWS Black Belt Online Seminar</a><br/>
        ・<a href="https://gallery.ecr.aws/" target="blank">Amazon ECR Public Gallery</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/01_%E3%82%BF%E3%83%83%E3%83%95%E3%82%9A%E3%83%AB%E6%A7%98_%E8%B3%87%E6%96%99.pdf" target="blank">ECS EC2からFargateへ移行した理由とメリット</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/202108_AWS_Black_Belt_Containers202-ECSFargate.pdf#page=12" target="blank">ECS での Fargate ⼊⾨</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200422_BlackBelt_Amazon_ECS_Share.pdf#page=17" target="blank">[AWS Black Belt Online Seminar] Amazon Elastic Container Service</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-44_AWS_Summit_Online_2020_Sumzap.pdf#page=22" target="blank">『このファン』におけるマネージドサービスドリブンなアーキテクチャへの挑戦</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonECS/latest/userguide/using_firelens.html" target="blank">カスタムログルーティング</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/fargate-fiirelens-fluentbit/" target="blank">【外部】AWS FargateでFireLensを使って同じログを3箇所に送ってみた</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/G-5.pdf" target="blank">AWSサービスで実現するバッチ実行環境のコンテナ/サーバレス化</a><br/>
        ・<a href="https://aws.amazon.com/jp/training/classroom/running-containers-on-amazon-elastic-kubernetes-service-amazon-eks/" target="blank">Running Containers on Amazon Elastic Kubernetes Service (Amazon EKS)</a><br/>
        ・<a href="https://github.com/aws/containers-roadmap/projects/1?card_filter_query=eks" target="blank">aws / containers-roadmap (Github)</a><br/>
        ・<a href="https://techblog.kayac.com/migrate-machino-coin-from-eks-to-ecs" target="blank">【外部】EKSからECSに移行して開発運用コストの削減を図る</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/container-build-lens/container-build-lens.html" target="blank">Container Build Lens</a><br/>
        <hr/>
        <h5>モジュール7（継続的インテグレーション/継続的デリバリー (CI/CD)）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/security-pillar/sec_appsec_deploy_software_programmatically.html" target="blank">SEC11-BP06 ソフトウェアをプログラムでデプロイする</a><br/>
        ・<a href="https://12factor.net/ja/" target="blank">The Twelve-Factor App</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20201111_BlackBelt_AWS%20CodeStar_AWS_CodePipeline.pdf#page=14" target="blank">[AWS Black Belt Online Seminar] AWS CodeStar & AWS CodePipeline（リリースプロセスのレベル）</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20201111_BlackBelt_AWS%20CodeStar_AWS_CodePipeline.pdf#page=52" target="blank">[AWS Black Belt Online Seminar] AWS CodeStar & AWS CodePipeline（構成例: Amazon ECS での CI/CD パイプライン）</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-07_AWS-Summit-2022_Sony-Interactive-Entertainment.pdf#page=34" target="blank">PlayStation®5 のユーザー体験を支える仕組みとローンチまでの取り組み</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/how-to-migrate-your-aws-codecommit-repository-to-another-git-provider/" target="blank">AWS CodeCommit リポジトリを他の Git プロバイダーに移行する方法</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonECS/latest/userguide/deployment-type-ecs.html" target="blank">ローリング更新</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-77_AWS_Summit_Online_2020_Akatsuki.pdf" target="blank">6000個のセキュリティポリシーを自動監査！アカツキ流、AWSセキュリティの取り組み紹介</a><br/>
        ・<a href="https://tech.nri-net.com/entry/controltower_afc" target="blank">【外部】AWS Control TowerのAccount Factoryでカスタマイズ設定を追加できるようになりました</a><br/>
        <hr/>
        <h5>モジュール8（高可用性と DDoS）</h5>
        ・<a href="https://aws.amazon.com/jp/shield/faqs/" target="blank">AWS Shield のよくある質問</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20170718_AWS-BlackBelt-Shield.pdf#page=17" target="blank">【AWS Black Belt Online Seminar】AWS Shield（AWS Shield Standard）</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20200818_AWS_BlackBelt_AWS_Shield_Advanced.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Shield Advanced</a><br/>
        ・<a href="https://aws.amazon.com/jp/shield/pricing/" target="blank">AWS Shield 料金</a><br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/pricing/" target="blank">AWS サポートのプランの料金</a><br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/jp_shield_support/" target="blank">AWS Shield Advanced における日本語サポートの制約事項</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202308/coconala-ddos-countermeasure/" target="blank">ココナラが DDoS 攻撃の脅威を分析し、対策を実現するまで</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/waf/latest/developerguide/aws-managed-rule-groups-list.html" target="blank">AWS マネージドルールのルールグループのリスト</a><br/>
        ・<a href="https://tech.smarthr.jp/entry/2020/11/24/150008" target="blank">【外部】SmartHRにおけるAWS WAF運用術</a><br/>
        ・<a href="https://wa.aws.amazon.com/wellarchitected/2020-07-02T19-33-23/wat.question.SEC_5.ja.html" target="blank">SEC 5: ネットワークリソースをどのように保護しますか? </a><br/>
        <hr/>
        <h5>モジュール9（データの保護）</h5>
        ・<a href="https://dnsops.jp/event/20140627/DNS_Summer_Days_2014-HSM.pdf" target="blank">【外部】暗号鍵を保護するHSM</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2024_AWS_KeyManagementService_0430_v1.pdf" target="blank">AWS Key Management Service (AWS KMS)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/kms/latest/developerguide/concepts.html#enveloping" target="blank">エンベロープ暗号化</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/kms/latest/cryptographic-details/intro.html" target="blank">AWS KMS の暗号化の詳細説明</a><br/>
        ・<a href="https://aws.amazon.com/jp/kms/faqs/" target="blank">AWS Key Management Service よくある質問</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/new-amazon-s3-dual-layer-server-side-encryption-with-keys-stored-in-aws-key-management-service-dsse-kms/" target="blank">新規 — AWS Key Management Service (DSSE-KMS) に保存されたキーによる Amazon S3 二重層サーバー側暗号化</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2023/05/aws-kms-hsm-fips-security-level-3/" target="blank">AWS KMS HSMs upgraded to FIPS 140-2 Security Level 3</a><br/>
        ・<a href="https://warp.ndl.go.jp/info:ndljp/pid/12446699/www.ipa.go.jp/security/enc/smartcard/node59.html" target="blank">IV.1.1 セキュリティレベル</a><br/>
        ・<a href="https://warp.ndl.go.jp/info:ndljp/pid/12446699/www.ipa.go.jp/security/enc/smartcard/node60.html" target="blank">IV.1.2 セキュリティ要件</a><br/>
        ・<a href="https://csrc.nist.gov/CSRC/media/projects/cryptographic-module-validation-program/documents/security-policies/140sp4523.pdf" target="blank">FIPS 140-2 Non-Proprietary Security Policy (AWS Key Management Service HSM)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/kms/latest/developerguide/custom-key-store-overview.html" target="blank">カスタムキーストア</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cloudhsm/latest/userguide/pkcs11-mechanisms.html" target="blank">サポートされているメカニズム</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cloudhsm/latest/userguide/third-party-applications.html" target="blank">AWS CloudHSM とサードパーティー製アプリケーションを統合します</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/security-pillar/sec_identities_secrets.html" target="blank">SEC02-BP03 シークレットを安全に保存して使用する</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_AWS-Secrets-Manager_0901_v1.pdf" target="blank">AWS Black Belt Online Seminar - AWS Secrets Manager</a><br/>
        <hr/>
        <h5>モジュール10（大規模データストア）</h5>
        ・<a href="https://aws.amazon.com/jp/s3/pricing/" target="blank">Amazon S3 の料金</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_Amazon-S3-CostOptimization_0602_v1.pdf" target="blank">Amazon Simple Storage Service (Amazon S3)　コスト最適化編</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_Amazon_S3_Security_0131_v1.pdf" target="blank">Amazon Simple Storage Service (Amazon S3)　セキュリティ編</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_AWS-Lake-Formation_1010_v1.pdf" target="blank">AWS BLACKBELT ONLINE SEMINAR　AWS Lake Formation　基礎編</a><br/>
        ・<a href="https://speakerdeck.com/himaoka/detareikuniokeruquan-xian-zhi-yu-shi-jian-lakeformation?slide=25" target="blank">データレイクにおける権限制御 実践LakeFormation</a><br/>
        <hr/>
        <h5>モジュール11（ワークロードの移行）</h5>
        ・<a href="https://aws.amazon.com/jp/training/classroom/migrating-to-aws/" target="blank">Migrating to AWS</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/JP_Migrate-with-AWS-Webinar_FINAL.pdf" target="blank">AWS への移行　～クラウドジャーニーを支援する最適なソリューションとサービス～</a><br/>
        ・<a href="https://cloudreadiness.amazonaws.com/#/cart" target="blank">AWS クラウド導入準備ツール (CART)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/itx-package-support-customers-migration/" target="blank">AWS ITトランスフォーメーションパッケージ – 評価・移行計画立案・移行の３フェーズで、クラウドへの移行を成功させる</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202007/awsgeek-migration-steps/" target="blank">クラウド移行の成功に必要なステップをグラレコで解説</a><br/>
        ・<a href="https://www.youtube.com/watch?v=yp0F09vaC6o" target="blank"> 移行戦略 (7R) の概要【AWS Black Belt】 </a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/psa/migration_tools/" target="blank">これだけは押さえておきたい、AWS移行全12ツール一挙紹介！</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/H2-04.pdf" target="blank">ネクソンの多彩なゲームコンテンツポートフォリオを支える基盤と運用の最適化について</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_AWS_MGN_0430_v1.pdf" target="blank">AWS Application Migration Service (AWS MGN) 概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/vmware/" target="blank">VMware Cloud on AWS</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/202110_AWS_Black_Belt_VMware%20_Cloud_on_AWS.pdf" target="blank">VMware Cloud on AWS AWS Black Belt Online Seminar</a><br/>
        ・<a href="https://docs.vmware.com/jp/VMware-Cloud-on-AWS/index.html" target="blank">【外部】VMware Cloud on AWS ドキュメント</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20210316_AWSBlackBelt2021_AWS-DataSync-v1.pdf" target="blank">[AWS Black Belt Online Seminar] AWS DataSync</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/mountpoint-for-amazon-s3-generally-available-and-ready-for-production-workloads/" target="blank">Mountpoint for Amazon S3 – 一般公開されており、本番環境のワークロードにも対応可能</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20220623_19th_ISV_DiveDeepSeminar_PurposeBuiltDB.pdf" target="blank">Purpose-built Database へのいざない</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20170919_AWS-BlackBelt-DMS.pdf#page=19" target="blank">【AWS Black Belt Online Seminar】 AWS Database Migration Service (移行ツールの意思決定チャート)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/first-step-of-dms/" target="blank">はじめてAWS DMSを検討する際に読んでいただきたいこと</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20210216_Blackbelt_DatabaseMigrationService.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Database Migration Service</a><br/>
        <hr/>
        <h5>モジュール12（コストの最適化）</h5>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/8-AWS のベストプラクティスで見直してみるクラウド最適化.pdf" target="blank">AWS のベストプラクティスで見直してみるクラウド最適化</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-22_AWS_Summit_Online_2020_CMP01-CMP02.pdf#page=29" target="blank">コスト管理を再考する (Resource Tag / Cost Allocation Tag 活用の考慮点)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_manage_policies_supported-resources-enforcement.html" target="blank">強制をサポートするサービスとリソースタイプ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/config/latest/developerguide/required-tags.html" target="blank">required-tags</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/implementations/instance-scheduler-on-aws/" target="blank">AWS での Instance Scheduler</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-13_AWS_Summit_Online_2021_CMP01.pdf" target="blank">Amazon EC2ことはじめ　〜あらゆるワークロードに対応する豊富な選択肢とコスト最適化オプション〜</a><br/>
        ・<a href="https://www.amazon.co.jp/AWS%E3%82%B3%E3%82%B9%E3%83%88%E6%9C%80%E9%81%A9%E5%8C%96%E3%82%AC%E3%82%A4%E3%83%89%E3%83%96%E3%83%83%E3%82%AF-%E9%96%80%E7%95%91-%E9%A1%95%E5%8D%9A/dp/4046053550" target="blank">AWSコスト最適化ガイドブック</a><br/>
        <hr/>
        <h5>モジュール13（エッジのためのアーキテクチャ設計）</h5>
        ・<a href="https://aws.amazon.com/jp/blogs/news/amazon-cloudfront-introduces-origin-access-control-oac/" target="blank">Amazon CloudFront オリジンアクセスコントロール（OAC）のご紹介</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/high_availability_origin_failover.html" target="blank">CloudFront オリジンフェイルオーバーによる高可用性の最適化</a><br/>
        ・<a href="https://repost.aws/ja/knowledge-center/cloudfront-distribution-static-ip" target="blank">CloudFront は静的 IP アドレスをサポートしていますか?</a><br/>
        ・<a href="https://aws.amazon.com/jp/cloudfront/dynamic-content/" target="blank">Amazon CloudFront の動的なコンテンツ配信</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20201028_BlackBelt_Amazon_CloudFront_deep_dive.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon CloudFront deep dive</a><br/>
        ・<a href="https://aws.amazon.com/jp/lambda/edge/" target="blank">Lambda@Edge</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/lambda-at-the-edge.html" target="blank">Lambda@Edge を使用したエッジでのカスタマイズ</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/H3-05.pdf#page=24" target="blank">CloudFront＆Lambda@Edgeで１億ページの電子書籍を配信</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/edge-functions.html#edge-functions-choosing" target="blank">CloudFront Functions と Lambda@Edge の選択</a><br/>
        ・<a href="https://aws.amazon.com/jp/global-accelerator/customers/" target="blank">AWS Global Accelerator ご利用のお客様達</a><br/>
        ・<a href="https://aws.amazon.com/jp/global-accelerator/faqs/" target="blank">AWS Global Accelerator のよくある質問</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-29_AWS_Summit_Online_2020_NET02.pdf#page=37" target="blank">Amazon CloudFront と AWS Global Accelerator を使った AWS global network の活用</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/smartnews-2021/" target="blank">AWS 導入事例：スマートニュース株式会社 (Global Accelerator)</a><br/>
        ・<a href="https://speedtest.globalaccelerator.aws/#/" target="blank">AWS Global Accelerator Speed Comparison</a><br/>
        <hr/>
        <h5>その他（順不同）</h5>
        ----- 【トレーニング情報】Advanced Architecting on AWS 以外のトレーニング（分野）にご興味をお持ち頂いた方へ<br/>
        ・<a href="https://aws.amazon.com/jp/training/classroom/" target="blank">AWS クラスルームトレーニングの概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202203/way-to-choose-training/" target="blank">AWS 認定インストラクターによる有償トレーニングコースの選び方</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202204/what-is-training-benefit/" target="blank">AWS 有償トレーニングのメリットってなんだろう</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202205/way-to-enjoy-training/" target="blank"> AWS 有償トレーニングをもっと楽しむために</a><br/>
        ----- 【各種資料】今後の学習に役立つ各種資料集（他　ハンズオン）<br/>
        ・<a href="https://aws.amazon.com/jp/events/aws-event-resource/archive/" target="blank">AWS サービス別資料</a><br/>
        ・<a href="https://aws-samples.github.io/jp-contents-hub/" target="blank">AWS 日本語ハンズオン</a><br/>
        ・<a href="https://workshops.aws/" target="blank">AWS Workshops　＊基本英語</a><br/>
        ----- 【アーキテクチャ図】アーキテクチャ図の作成のヒント<br/>
        ・<a href="https://aws.amazon.com/jp/architecture/icons/" target="blank">AWS アーキテクチャアイコン</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202204/way-to-draw-architecture/" target="blank">AWS のアーキテクチャ図を描きたい ! でもどうすれば良いの ?</a><br/>
        ----- 【料金関連】AWS サービス周りのまずは料金を知りたい場合<br/>
        ・<a href="https://calculator.aws/#/" target="blank">AWS 料金見積りツール</a>
        <hr/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから720日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="https://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a><br/>
        &nbsp;&nbsp;※👆の資料のコードによる引き換えは現在方式として（当コースの場合）運用が変更されております。前述の「受講者ポータル」より「受講者ガイド」および「ラボガイド」を引き換え下さい。（要 Bookshelf 登録）
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日を目処に削除します。
      </div>
    </main>
  );
};

export default MainContents;
